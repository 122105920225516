<template>
  <el-main>
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="赛程名称：">
        <el-input size="small" v-model="searchForm.events_name"></el-input>
      </el-form-item>
      <el-form-item label="战队名称：">
        <el-input size="small" v-model="searchForm.team_name"></el-input>
      </el-form-item>
      <el-form-item label="队长姓名：">
        <el-input size="small" v-model="searchForm.user_name"></el-input>
      </el-form-item>
      <el-form-item label="队长手机号：">
        <el-input size="small" v-model="searchForm.mobile"></el-input>
      </el-form-item>
      <el-form-item label="创建时间：">
        <el-date-picker v-model="searchForm.start_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small"></el-date-picker>
      </el-form-item>
      <el-form-item label="报名时间：">
        <el-date-picker v-model="searchForm.apply_success_start_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small"></el-date-picker>
      </el-form-item>
      <el-form-item label="报名状态：">
        <el-select size="small" v-model="searchForm.status">
          <el-option label="全部" value=""></el-option>
          <el-option label="已解散" :value="-1"></el-option>
          <el-option label="报名中" :value="1"></el-option>
          <el-option label="报名成功锁定" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" @click="search" type="primary">搜索</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="events_name" label="赛程名称" align="center"></el-table-column>
      <el-table-column prop="team_name" label="战队名称" align="center"></el-table-column>
      <el-table-column prop="logo" label="战队头像" align="center">
        <template v-slot="{ row }">
          <el-image style="width: 100px; height: 70px" :src="row.logo"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="user_name" label="队长名称" align="center"></el-table-column>
      <el-table-column prop="mobile" label="队长手机号" align="center"></el-table-column>
      <el-table-column prop="team_user_count" label="战队队员人数" align="center"></el-table-column>
      <el-table-column prop="team_count" label="战队限制人数" align="center"></el-table-column>
      <el-table-column prop="is_full" label="是否满员" align="center">
        <template v-slot="{ row }">{{ row.is_full ? '是' : '否' }}</template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column prop="apply_success_time" label="报名时间" align="center">
        <template v-slot="{ row }">{{ row.apply_success_time ? getDateformat(row.apply_success_time) : '--' }}</template>
      </el-table-column>
      <el-table-column prop="status" label="报名状态" align="center">
        <template v-slot="{ row }">
          {{ row.status == 1 ? '报名中' : row.status == 2 ? '报名成功锁定' : '已解散' }}
        </template>
      </el-table-column>
      <el-table-column prop="money" label="操作" align="center">
        <template v-slot="{ row }">
          <el-button @click="seeTeam(row)" size="small" type="text">队伍查看</el-button>
          <el-button v-if="row.status >= 1" @click="lockTeam(row)" size="small" type="text">{{ row.status == 1 ? '锁定报名' : '解除锁定' }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="队伍查看" :visible.sync="showTeam" width="650px">
      <div class="team-name">战队名称：{{ teamName }}</div>
      <el-table :data="teamList" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="user_name" label="报名人姓名" align="center"></el-table-column>
        <el-table-column prop="is_team_leader" label="报名人身份" align="center">
          <template v-slot="{ row }">
            {{ row.is_team_leader ? '队长' : '队员' }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
        <el-table-column prop="qq" label="QQ号码" align="center"></el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template v-slot="{ row }">
            {{ row.status == -1 ? '审核驳回' : row.status == -2 ? '已取消' : row.status == -3 ? '已退出' : row.status == 1 ? '审核中' : '审核通过' }}
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showTeam = !1">取 消</el-button>
        <el-button type="primary" @click="showTeam = !1">确 定</el-button>
      </span>
    </el-dialog>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        events_name: '',
        team_name: '',
        user_name: '',
        mobile: '',
        start_time: '',
        end_time: '',
        apply_success_start_time: '',
        apply_success_end_time: '',
        status: '',
      },
      page: 1,
      rows: 10,
      showTeam: !1,
      list: [],
      teamList: [],
      total_number: 0,
      teamName: '',
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    search() {
      this.searchForm.page = 1;
      this.searchForm.rows = 10;
      this.getList();
    },
    searchClear() {
      this.searchForm = {
        page: 1,
        rows: 10,
        events_name: '',
        team_name: '',
        user_name: '',
        mobile: '',
        start_time: '',
        end_time: '',
        apply_success_start_time: '',
        apply_success_end_time: '',
        status: '',
      };
      this.getList();
    },
    getList() {
      this.$axios.post(this.$api.sports.teamList, this.searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    seeTeam(row) {
      this.teamName = row.team_name;
      this.$axios
        .post(this.$api.sports.teamUserList, {
          page: 1,
          rows: 100,
          id: row.id,
        })
        .then(res => {
          if (res.code == 0) {
            console.log(res);
            this.teamList = res.result.list;
            this.showTeam = !0;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    lockTeam(row) {
      if (!row.is_full) return this.$message.warning('战队人数未满，无法锁定');
      this.$axios
        .post(this.$api.sports.updateStatus, {
          id: row.id,
          status: row.status == 1 ? 2 : 1,
        })
        .then(res => {
          if (res.code == 0) {
            row.status = row.status == 1 ? 2 : 1;
            this.$message.success(row.status == 2 ? '已成功锁定队伍' : '已成功解除队伍');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    setChampion(row) {
      if (row.is_champion_team) {
        this.$axios
          .post(this.$api.sports.unlockTeam, {
            id: row.id,
            is_champion_team: 0,
          })
          .then(res => {
            if (res.code == 0) {
              row.is_champion_team = 0;
              this.$message.success('已成功解除队伍冠军');
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.$axios
          .post(this.$api.sports.lockTeam, {
            id: row.id,
            is_champion_team: 1,
          })
          .then(res => {
            if (res.code == 0) {
              row.is_champion_team = 1;
              this.$message.success('已成功设置队伍冠军');
            } else {
              this.$message.error(res.msg);
            }
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .team-name {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 15px;
  }
}
</style>